var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { h } from "preact";
export var formatOption = function (x) { return ({
    value: x,
    label: x.toString(),
}); };
export var numberRangeAsOption = function (rangeEnd) {
    return __spread(Array(rangeEnd).keys()).map(function (x) { return formatOption(x + 1); });
};
export var numberRangeAsOptionStartAtZero = function (rangeEnd) { return __spread(Array(rangeEnd).keys()).map(function (x) { return formatOption(x); }); };
export var percentRangeAsOption = function (rangeEnd) {
    return numberRangeAsOptionStartAtZero(rangeEnd).map(function (o) { return ({
        label: o.label + "%",
        value: o.value,
    }); });
};
export var Question = function (props) {
    var handleChange = function (event) {
        var value = event.currentTarget.value;
        if (props.onChange) {
            props.onChange(parseInt(value));
        }
    };
    return (h("div", null,
        h("label", { htmlFor: props.id, class: "font-display text-xs" }, props.label),
        h("select", { onChange: handleChange, name: props.id, id: props.id, className: "bg-white border border-grayMid rounded-none py-2 px-4 block w-full appearance-none leading-normal mb-4 mt-2 font-body text-xs" }, props.options.map(function (o) { return (h("option", { key: o.value, value: o.value, selected: props.selectedValue === o.value }, o.label)); }))));
};
