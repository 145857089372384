export var color = {
    'grayDark': "#1F1F1F",
    'grayMid': "#666666",
    'grayGraph': "#979797",
    'grayLight': "#F4F4F5",
    'white': "#FFFFFF",
    'green': "#86D800",
    'violet': "#8E10A5",
    'yellow': "#FAC800",
    'violetAction': "#59598B",
};
