export var industryList = [
    { value: 1, label: "Arkkitehtisuunnittelu" },
    { value: 2, label: "Isännöinti" },
    { value: 3, label: "Kalusto- ja muut palvelut" },
    {
        value: 4,
        label: "Kiinteistö- ja asuntokauppa sekä -vuokraus",
    },
    { value: 5, label: "Kiinteistön hoito- ja ylläpitopalvelut" },
    { value: 6, label: "Kiinteistöomistus" },
    { value: 7, label: "Koulutus ja tutkimus" },
    { value: 8, label: "Muut kiinteistöalan palvelut" },
    { value: 9, label: "Rahoitus ja vakuutus" },
    { value: 10, label: "Rakennustoiminta" },
    { value: 11, label: "Rakennuttaminen" },
    { value: 12, label: "Rakennustuoteteollisuus" },
    { value: 13, label: "Suunnittelu ja konsultointi" },
    { value: 14, label: "Talotekniikka" },
    { value: 15, label: "Taloyhtiö" },
    { value: 16, label: "Viranomaiset" },
];
export var formText = {
    title: "#kynätpois – luopumalla säästät! Laske, kuinka paljon.",
    badge: "Powered by Kirahub"
};
export var formLabels = {
    industry: "Valitse toimialasi",
    signedContracts: "Arvioi allekirjoitusten määrä organisaatiossasi per kk",
    copies: "Montako kopiota näistä asiakirjoista tarvitaan keskimäärin?",
    pages: "Montako sivua asiakirjat sisältävät keskimäärin?",
    percentHeader: "Miten asiakirjat allekirjoitetaan? (Arvio, yht. 100%)",
    percentageByMail: "1) Tulostus, kopioiden allekirjoitus ja postitus",
    percentageByElectronicMail: "2) Tulostus, kopioiden allekirjoitus, skannaus ja lähetys",
    percentageByDigital: "3) Sähköinen allekirjoitus alusta loppuun",
    button: "Laske organisaationi säästö",
};
export var errorStates = {
    emailInvalid: "Email is invalid"
};
export var radarChartLabels = {
    fin: "Taloudellinen (säästö euroina)",
    soc: "Sosiaalinen (työtunnit)",
    env: "Ympäristö (paperimäärä)",
    legendAverage: "Oman toimialani vastausten keskiarvo",
    legendChart: "Organisaationi säästöpotentiaali",
};
export var CallToActionText = {
    title: "Lataa tulokset itsellesi ja osaksi kampanjaa!",
    labelEmail: "Sähköpostini",
    placeholderEmail: "nimi@osoite.com",
    button: "Lähetä",
    labelYesResults: "To be hidden",
    subheadYesResults: "",
    labelYesMailingList: "Haluan tulokset sähköpostiini ja annan samalla luvan tallentaa ne kampanjan kokonaisvaikuuttavuuden seuraamiseksi",
    subheadYesMailingList: "Lähetämme sinulle organisaatiosi tulokset sähköpostilla ja lisäämme ne kampanjan tietokantaan. Tietokannan avulla KIRAHub seuraa kampanjan etenemistä ja vaikuttavuutta ja viestii siitä. Kampanjaviestinnässä käytetään vain yhdisteltyä ja anonymisoitua dataa. Tällä valinnallasi annat KIRAHubille luvan olla sinuun sähköpostitse yhteydessä vastauksiisi liittyen.",
    labelYesContact: "Haluan lisätietoja sähköisen allekirjoittamisen palveluista",
    subheadYesContact: "Sinuun ollaan yhteydessä sähköpostitse. Tällä valinnalla annat kampanjaa koordinoivalle KIRAHubille luvan jakaa antamasi sähköpostiosoite kampanjan kumppaneille. Heille ei jaeta muita antamiasi tietoja. Kampanjan kumppanina toimii SignSpace. Kumppanuusmahdollisuutta tarjottiin haasteen valmistelussa tunnistetuille palveluntarjoajille.",
};
export var ReportText = {
    title: "Arvioidut säästöt",
    env: {
        title: "#kynätpois – kohti päästötöntä tulevaisuutta",
        savingsTitle: "Säästyneiden paperiarkkien määrä vuosittain",
        savingsSubheadPre: "",
        savingsSubheadPost: " sivua",
        understandingTitle: "Vältetty hiilidioksidipäästö vuosittain",
        understandingSubheadPre: "",
        understandingSubheadPost: " Kg CO2",
        chartTitle: "Vältetty hiilidioksidipäästö vuosittain",
        chartDataLabel: "Kg CO2",
    },
    fin: {
        title: "#kynätpois – kohti tuottavampaa tulevaisuutta",
        savingsTitle: "Säästyvät kulut vuosittain",
        savingsSubheadPre: "",
        savingsSubheadPost: " eur",
        understandingTitle: "Uusia digikehityshankkeita vuosittain",
        understandingSubheadPre: "",
        understandingSubheadPost: " kpl",
        chartTitle: "Säästyvät kulut vuosittain",
        chartDataLabel: "eur",
    },
    soc: {
        title: "#kynätpois – kohti merkityksellisempää tulevaisuutta",
        savingsTitle: "Vapautuva aika vuosittain",
        savingsSubheadPre: "",
        savingsSubheadPost: " h",
        understandingTitle: "Henkilötyökuukautta suunnattavissa uudelleen vuosittain",
        understandingSubheadPre: "",
        understandingSubheadPost: " htkk",
        chartTitle: "Vapautuva aika vuosittain",
        chartDataLabel: " tuntia",
    },
};
export var SocialMediaText = {
    title: "Haasta kaikki mukaan somessa!",
    subhead: "Muista merkitä julkaisuun #kynätpois ja #kestävädigi",
    altTwitter: "Twitter",
    altLinkedin: "Linked In",
    altFacebook: "Facebook"
};
/* used in social banner below */
export var permalink = "https://www.kirahub.org/kynat-pois";
export var hashtags = ["kynätpois ", "kestävädigi"];
export var messageGeneric = "S\u00E4hk\u00F6inen allekirjoitus koko KIRA-alalle! Olemme mukana, otatko haasteen vastaan?";
export var messageFin = function (value) {
    return "My company can save over " + value + "k euros per year. What about yours? Try this calculator";
};
export var messageSoc = function (value) {
    return "My company can save over " + value + " hours per year. What about yours? Try this calculator";
};
export var messageEnv = function (value) {
    return "My company can save over " + value + " trees per year. What about yours? Try this calculator";
};
