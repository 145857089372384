import { h } from "preact";
import { Chart } from "../Chart";
import { color } from "../../styles/tokens";
var bubbleText = function (smallText, largeText, imageURL) { return (h("div", null,
    h("p", { className: "font-body text-tiny" },
        h("img", { src: imageURL, style: {
                display: "inline-block",
                marginRight: "4px",
            }, alt: "" }),
        smallText),
    h("p", { className: "font-display text-lg ml-10" }, largeText))); };
export var ReportSection = function (props) { return (h("div", { className: "flex flex-wrap pt-12 pb-16" },
    h("div", { className: "w-full md:w-5/6 mx-auto px-4" },
        h("p", { className: "font-display text-lg" }, props.title),
        h("hr", null),
        h("div", { className: "flex flex-wrap" },
            h("div", { className: "w-full md:w-1/2 pt-8" },
                bubbleText(props.savingsTitle, props.savingsSubhead, props.imageSavingsURL),
                h("p", { className: "font-display text-lg text-center" }, "="),
                bubbleText(props.understandingTitle, props.understandingSubhead, props.imageUnderstandingURL)),
            h("div", { className: "w-full md:w-1/2 pt-12" },
                h("p", { className: "font-body text-tiny ml-10" }, props.chartTitle),
                h(Chart, { color: color[props.color], data: props.chartData, dataLabel: props.chartDataLabel })))))); };
export default ReportSection;
