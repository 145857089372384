import { h } from "preact";
export var QuestionNumber = function (props) {
    var handleChange = function (event) {
        var value = event.currentTarget.value;
        if (props.onChange) {
            props.onChange(parseInt(value));
        }
    };
    return (h("div", null,
        h("label", { htmlFor: props.id, class: "font-display text-xs" }, props.label),
        h("input", { name: props.id, id: props.id, className: "bg-white border border-grayMid rounded-none py-2 px-4 block w-full appearance-none leading-normal mb-4 mt-2 font-body text-xs w-full", type: "number", min: props.min, max: props.max, onChange: handleChange, value: props.value })));
};
