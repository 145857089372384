import { h } from "preact";
import ReactFrappeChart from "react-frappe-charts";
export var Chart = function (props) { return (h(ReactFrappeChart, { type: "bar", colors: [props.color], axisOptions: { xAxisMode: "tick", yAxisMode: "span", xIsSeries: 1 }, barOptions: {
        spaceRatio: 0.7,
        stacked: 1,
        height: 1,
        depth: 1,
    }, height: 334, data: {
        labels: ["y1", "y2", "y3", "y4", "y5"],
        datasets: [{ name: props.dataLabel, values: props.data }],
    } })); };
