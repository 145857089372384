import { h } from "preact";
import { SocialMediaText, messageFin, messageSoc, messageEnv, messageGeneric, hashtags, permalink } from "../../tokens";
import { imageURL } from ".";
var messageFn = [messageFin, messageSoc, messageEnv];
/** expectes URL encoded permalink */
var facebookURI = function (permalink) {
    /** expectes URL encoded permalink */
    return "https://www.facebook.com/sharer/sharer.php?u=" + permalink;
};
var linkedinURI = function (permalink) {
    /** expectes URL encoded tweetText, including URL passed */
    return "https://www.linkedin.com/shareArticle?mini=true&url=" + permalink;
};
var twitterURI = function (tweetText) {
    return "https://twitter.com/intent/tweet?text=" + tweetText;
};
export var SocialBanner = function (props) {
    var tweetText = messageGeneric + " " + hashtags
        .map(function (h) { return "#" + h; })
        .join(" ")
        .toString() + " " + permalink;
    return (h("div", { className: "bg-grayLight p-6 my-3 mt-8 mb-2" },
        h("div", { className: "flex flex-wrap content-center" },
            h("div", { className: "w-full md:w-2/3" },
                h("p", { className: "font-display text-lg mb-1" },
                    " ",
                    SocialMediaText.title),
                h("p", { className: "font-body text-lg" }, SocialMediaText.subhead)),
            h("div", { className: "w-1/3 md:w-1/12 mt-4 mb-2" },
                h("a", { href: twitterURI(encodeURIComponent(tweetText)), target: "_blank", rel: "noopener noreferrer" },
                    h("img", { src: imageURL.socialMedia.twitter, alt: SocialMediaText.altTwitter }))),
            h("div", { className: "w-1/3 md:w-1/12 mt-4 mb-2" },
                h("a", { href: linkedinURI(encodeURIComponent(permalink)), target: "_blank", rel: "noopener noreferrer" },
                    h("img", { src: imageURL.socialMedia.linkedin, alt: SocialMediaText.altLinkedin }))),
            h("div", { className: "w-1/3 md:w-1/12 mt-4 mb-2" },
                h("a", { href: facebookURI(encodeURIComponent(permalink)), target: "_blank", rel: "noopener noreferrer" },
                    h("img", { src: imageURL.socialMedia.facebook, alt: SocialMediaText.altFacebook }))))));
};
export default SocialBanner;
