var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { h, Component } from "preact";
import { Question, numberRangeAsOption, percentRangeAsOption, } from "../Question";
import RadarChart from "../RadarChart";
import SocialBanner from "./SocialBanner";
import ReportSection from "./ReportSection";
import { calculateImpact, pagesUsedPerYear, carbonFromPaperPerYear, calcFinacialSavingsPerYear, hoursSavedPerYear, savedPersonMonthsPerYear, sprintsPerYear, } from "../../calculator";
import { QuestionNumber } from "../QuestionNumber";
import { CallToAction } from "./CallToAction";
import { formLabels, formText, radarChartLabels, industryList, ReportText, errorStates, } from "../../tokens";
import { fixToOneDecimalPlace, fixToTwoDecimalPlaces, getIndustryName } from "../../helper";
var URL_THANK_YOU_PAGE = "https://kirahub.org/en/?page_id=4681";
var API_SUBMISSION_URL = "https://kirahub-api.herokuapp.com/pledge";
var VALID_EMAIL_REGEX = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
export var imageURL = {
    poweredByBadge: "https://kirahub.org/wp-content/uploads/2020/05/kp_iconmusta_laskuri-e1589296829619.png",
    env: {
        saving: "https://kirahub-widget.netlify.app/assets/svg/paper.svg",
        understanding: "https://kirahub-widget.netlify.app/assets/svg/co2.svg",
    },
    fin: {
        saving: "https://kirahub-widget.netlify.app/assets/svg/hands.svg",
        understanding: "https://kirahub-widget.netlify.app/assets/svg/rocket.svg",
    },
    soc: {
        understanding: "https://kirahub-widget.netlify.app/assets/svg/calendar.svg",
        saving: "https://kirahub-widget.netlify.app/assets/svg/clock.svg",
    },
    socialMedia: {
        facebook: "https://kirahub-widget.netlify.app/assets/svg/socialmedia-facebook-48px.svg",
        linkedin: "https://kirahub-widget.netlify.app/assets/svg/socialmedia-linkedin-48px.svg",
        twitter: "https://kirahub-widget.netlify.app/assets/svg/socialmedia-twitter-48px.svg",
    },
};
/* for local development below */
// export const imageURL = {
//   poweredByBadge: "/assets/png/powered-by.png",
//   env: {
//     saving: "/assets/svg/paper.svg",
//     understanding: "/assets/svg/co2.svg",
//   },
//   fin: {
//     saving: "/assets/svg/rocket.svg",
//     understanding: "/assets/svg/hands.svg",
//   },
//   soc: {
//     saving: "/assets/svg/calendar.svg",
//     understanding: "/assets/svg/clock.svg",
//   },
//   socialMedia: {
//     facebook:
//       "/assets/svg/socialmedia-facebook-48px.svg",
//     linkedin:
//       "/assets/svg/socialmedia-linkedin-48px.svg",
//     twitter:
//       "/assets/svg/socialmedia-twitter-48px.svg",
//   },
// };
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            // FIXME: for debugging
            showForm: false,
            // showForm: true,
            industry: 1,
            signedContracts: 15,
            copies: 2,
            pages: 5,
            percentageByMail: 50,
            percentageByElectronicMail: 40,
            percentageByDigital: 10,
            email: "",
            yesSubmitResults: false,
            yesMailingList: false,
            yesContactMe: false,
            error: {
                email: false,
                emailMessage: "",
            },
            animateBar: false,
        };
        _this.handleFormSubmit = _this.handleFormSubmit.bind(_this);
        return _this;
    }
    /** update state when a question is updated */
    App.prototype.updateValue = function (valueToUpdate, value) {
        var _a;
        this.setState((_a = {}, _a[valueToUpdate] = value, _a));
        if (this.state.showForm)
            this.handleAnimateBar();
    };
    App.prototype.handleAnimateBar = function () {
        var _this = this;
        this.setState({ animateBar: true });
        setTimeout(function () {
            _this.setState({
                animateBar: false,
            });
        }, 600);
    };
    /** just show the bottom of the calculator once the button is hit */
    App.prototype.handleCalculate = function () {
        this.setState({ showForm: true });
        this.handleAnimateBar();
    };
    App.prototype.handleFormSubmit = function () {
        /* FIXME: Sideeffects and duplication of render methods below */
        var _a = this.state, industry = _a.industry, signedContracts = _a.signedContracts, copies = _a.copies, pages = _a.pages, percentageByMail = _a.percentageByMail, percentageByElectronicMail = _a.percentageByElectronicMail, percentageByDigital = _a.percentageByDigital, email = _a.email, yesSubmitResults = _a.yesSubmitResults, yesMailingList = _a.yesMailingList, yesContactMe = _a.yesContactMe;
        var impact = calculateImpact(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100);
        var fin = impact.fin, soc = impact.soc, env = impact.env;
        var reportPagesPerYear = fixToOneDecimalPlace(pagesUsedPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportCarbonFromPaperPerYear = fixToOneDecimalPlace(carbonFromPaperPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportSavingsInEurosYearly = fixToTwoDecimalPlaces(calcFinacialSavingsPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportSprintsPerYear = fixToOneDecimalPlace(sprintsPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportHoursSavedPerYear = fixToOneDecimalPlace(hoursSavedPerYear(signedContracts, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportSavedPersonMonthsPerYear = fixToTwoDecimalPlaces(savedPersonMonthsPerYear(signedContracts, percentageByMail / 100, percentageByElectronicMail / 100));
        /* lots of eslint below because of the database naming convention */
        var formattedData = {
            data: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                industry_id: industry,
                // eslint-disable-next-line @typescript-eslint/camelcase
                industry_name: getIndustryName(industry),
                // eslint-disable-next-line @typescript-eslint/camelcase
                signed_contracts: signedContracts,
                copies: copies,
                pages: pages,
                // eslint-disable-next-line @typescript-eslint/camelcase
                percentage_by_mail: percentageByMail,
                // eslint-disable-next-line @typescript-eslint/camelcase
                percentage_by_electronic_mail: percentageByElectronicMail,
                // eslint-disable-next-line @typescript-eslint/camelcase
                percentage_by_digital: percentageByDigital,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_fin: fin,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_soc: soc,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_env: env,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_yearly_pages: reportPagesPerYear,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_yearly_carbon_from_paper: reportCarbonFromPaperPerYear,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_yearly_savings_in_euros: reportSavingsInEurosYearly,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_yearly_sprints: reportSprintsPerYear,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_yearly_hours_saved: reportHoursSavedPerYear,
                // eslint-disable-next-line @typescript-eslint/camelcase
                calc_yearly_person_months_saved: reportSavedPersonMonthsPerYear,
                // eslint-disable-next-line @typescript-eslint/camelcase
                contact_email: email,
                // eslint-disable-next-line @typescript-eslint/camelcase
                contact_yes_submit_results: yesSubmitResults,
                // eslint-disable-next-line @typescript-eslint/camelcase
                contact_yes_mailing_list: yesMailingList,
                // eslint-disable-next-line @typescript-eslint/camelcase
                contact_yes_contact_me: yesContactMe,
            },
        };
        if (!VALID_EMAIL_REGEX.test(this.state.email) || this.state.email === "") {
            this.setState({
                error: { email: true, emailMessage: errorStates.emailInvalid },
            });
            var elemt = document.getElementById("cta-email-label");
            if (elemt)
                elemt.scrollIntoView();
        }
        else {
            fetch(API_SUBMISSION_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(formattedData),
            }).then(function (response) {
                if (response.ok === true) {
                    window.location.href = URL_THANK_YOU_PAGE;
                }
                else {
                    // maybe error state
                }
            });
        }
    };
    App.prototype.handleEmailChange = function (e) {
        this.setState({ email: e });
    };
    App.prototype.render = function () {
        var _this = this;
        /* show social share and cta in landing-page mode */
        var landingPageMode = this.props.mode === "landing-page";
        var _a = this.state, showForm = _a.showForm, industry = _a.industry, signedContracts = _a.signedContracts, copies = _a.copies, pages = _a.pages, percentageByMail = _a.percentageByMail, percentageByElectronicMail = _a.percentageByElectronicMail, percentageByDigital = _a.percentageByDigital, email = _a.email, yesSubmitResults = _a.yesSubmitResults, yesMailingList = _a.yesMailingList, yesContactMe = _a.yesContactMe;
        var impact = calculateImpact(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100);
        var fin = impact.fin, soc = impact.soc, env = impact.env;
        var reportPagesPerYear = fixToOneDecimalPlace(pagesUsedPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportCarbonFromPaperPerYear = fixToOneDecimalPlace(carbonFromPaperPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportSavingsInEurosYearly = fixToTwoDecimalPlaces(calcFinacialSavingsPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportSprintsPerYear = fixToOneDecimalPlace(sprintsPerYear(signedContracts, pages, copies, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportHoursSavedPerYear = fixToOneDecimalPlace(hoursSavedPerYear(signedContracts, percentageByMail / 100, percentageByElectronicMail / 100));
        var reportSavedPersonMonthsPerYear = fixToTwoDecimalPlaces(savedPersonMonthsPerYear(signedContracts, percentageByMail / 100, percentageByElectronicMail / 100));
        return (h("div", { className: "kirahub-kynat-pois-widget" },
            h("div", { className: this.state.animateBar ? "gradient-bar animate" : "gradient-bar" }),
            h("div", { class: "shadow-lg bg-white border-0 border-l-2 border-r-2 border-b-2 border-yellow" },
                h("div", { class: "flex flex-wrap" },
                    h("div", { class: "w-full md:w-1/2 bg-grayLight px-4 md:px-12 pt-3 md:pt-6" },
                        h("div", { className: "flex justify-end" },
                            h("img", { src: imageURL.poweredByBadge, alt: formText.badge, className: "md:hidden mb-2" })),
                        h("p", { class: "font-display text-xl mb-8 leading-tight" }, this.props.title || formText.title),
                        h(Question, { label: formLabels.industry, id: "select-industry-subsector", selectedValue: industry, onChange: function (value) {
                                _this.updateValue("industry", value);
                            }, options: industryList }),
                        h(QuestionNumber, { id: "selector-signedContracts", onChange: function (value) {
                                _this.updateValue("signedContracts", value);
                            }, label: formLabels.signedContracts, min: 1, max: 500, value: signedContracts }),
                        h(Question, { id: "selector-copies", selectedValue: copies, onChange: function (value) {
                                _this.updateValue("copies", value);
                            }, label: formLabels.copies, options: numberRangeAsOption(20) }),
                        h(Question, { id: "selector-pages", selectedValue: pages, onChange: function (value) {
                                _this.updateValue("pages", value);
                            }, label: formLabels.pages, options: numberRangeAsOption(150) }),
                        h("p", { className: "font-display text-xs" }, formLabels.percentHeader),
                        h(Question, { id: "selector-percentage-mailed", selectedValue: percentageByMail, onChange: function (value) {
                                _this.updateValue("percentageByMail", value);
                            }, label: formLabels.percentageByMail, options: percentRangeAsOption(101) }),
                        h(Question, { id: "selector-percentage-mailed-electronic", selectedValue: percentageByElectronicMail, onChange: function (value) {
                                _this.updateValue("percentageByElectronicMail", value);
                            }, label: formLabels.percentageByElectronicMail, options: percentRangeAsOption(101) }),
                        h(Question, { id: "selector-percentage-digital", selectedValue: percentageByDigital, onChange: function (value) {
                                _this.updateValue("percentageByDigital", value);
                            }, label: formLabels.percentageByDigital, options: percentRangeAsOption(101) }),
                        h("div", { className: "p-3" }),
                        h("button", { class: "bg-yellow hover:bg-grayLight hover:border-2 border-2 border-yellow text-black font-display text-xs py-3 px-8 md:px-24", onClick: function () {
                                _this.handleCalculate();
                            } }, formLabels.button),
                        h("div", { className: "p-4" })),
                    h("div", { class: "w-full md:w-1/2 p-8 md:pt-120" },
                        h("div", { className: "relative" },
                            h("img", { src: imageURL.poweredByBadge, alt: formText.badge, className: "absolute right-0 md:-mt-96 invisible md:visible" })),
                        h("p", { className: "font-display text-base" }, ReportText.title),
                        h("hr", { style: { marginBottom: "3.5rem" } }),
                        showForm ? (h(RadarChart, { data: !this.state.animateBar
                                ? {
                                    fin: fin,
                                    soc: soc,
                                    env: env,
                                }
                                : null, labels: radarChartLabels })) : (h(RadarChart, { labels: radarChartLabels })))),
                showForm && (h("div", null,
                    landingPageMode && h(SocialBanner, { values: [fin, soc, env] }),
                    h(ReportSection, { title: ReportText.env.title, savingsTitle: ReportText.env.savingsTitle, savingsSubhead: "" + ReportText.env.savingsSubheadPre + reportPagesPerYear + ReportText.env.savingsSubheadPost, understandingTitle: ReportText.env.understandingTitle, understandingSubhead: "" + ReportText.env.understandingSubheadPre + reportCarbonFromPaperPerYear + ReportText.env.understandingSubheadPost, imageSavingsURL: imageURL.env.saving, imageUnderstandingURL: imageURL.env.understanding, chartTitle: ReportText.env.chartTitle, chartDataLabel: ReportText.env.chartDataLabel, color: "green", chartData: [
                            fixToOneDecimalPlace(reportCarbonFromPaperPerYear * 1),
                            fixToOneDecimalPlace(reportCarbonFromPaperPerYear * 2),
                            fixToOneDecimalPlace(reportCarbonFromPaperPerYear * 3),
                            fixToOneDecimalPlace(reportCarbonFromPaperPerYear * 4),
                            fixToOneDecimalPlace(reportCarbonFromPaperPerYear * 5),
                        ] }),
                    h(ReportSection, { title: ReportText.fin.title, savingsTitle: ReportText.fin.savingsTitle, savingsSubhead: "" + ReportText.fin.savingsSubheadPre + reportSavingsInEurosYearly + ReportText.fin.savingsSubheadPost, understandingTitle: ReportText.fin.understandingTitle, understandingSubhead: "" + ReportText.fin.understandingSubheadPre + reportSprintsPerYear + ReportText.fin.understandingSubheadPost, imageSavingsURL: imageURL.fin.saving, imageUnderstandingURL: imageURL.fin.understanding, chartTitle: ReportText.fin.chartTitle, chartDataLabel: ReportText.fin.chartDataLabel, color: "violet", chartData: [
                            fixToTwoDecimalPlaces(reportSavingsInEurosYearly * 1),
                            fixToTwoDecimalPlaces(reportSavingsInEurosYearly * 2),
                            fixToTwoDecimalPlaces(reportSavingsInEurosYearly * 3),
                            fixToTwoDecimalPlaces(reportSavingsInEurosYearly * 4),
                            fixToTwoDecimalPlaces(reportSavingsInEurosYearly * 5),
                        ] }),
                    h(ReportSection, { title: ReportText.soc.title, savingsTitle: ReportText.soc.savingsTitle, savingsSubhead: "" + ReportText.soc.savingsSubheadPre + reportHoursSavedPerYear + ReportText.soc.savingsSubheadPost, understandingTitle: ReportText.soc.understandingTitle, understandingSubhead: "" + ReportText.soc.understandingSubheadPre + reportSavedPersonMonthsPerYear + ReportText.soc.understandingSubheadPost, imageSavingsURL: imageURL.soc.saving, imageUnderstandingURL: imageURL.soc.understanding, chartTitle: ReportText.soc.chartTitle, chartDataLabel: ReportText.soc.chartDataLabel, color: "yellow", chartData: [
                            fixToOneDecimalPlace(reportHoursSavedPerYear * 1),
                            fixToOneDecimalPlace(reportHoursSavedPerYear * 2),
                            fixToOneDecimalPlace(reportHoursSavedPerYear * 3),
                            fixToOneDecimalPlace(reportHoursSavedPerYear * 4),
                            fixToOneDecimalPlace(reportHoursSavedPerYear * 5),
                        ] }),
                    landingPageMode && (h("div", { className: "flex justify-center bg-yellow py-8" },
                        h("div", { className: "w-3/4 md:w-1/3" },
                            h(CallToAction, { email: email, errorEmail: this.state.error.email, errorEmailMessage: this.state.error.emailMessage, yesSubmitResults: yesSubmitResults, yesMailingList: yesMailingList, yesContactMe: yesContactMe, handleSubmit: this.handleFormSubmit, handleCheckboxChange: function (data) {
                                    _this.setState(data);
                                }, handleEmailChange: function (e) { return _this.handleEmailChange(e); } })))))))));
    };
    return App;
}(Component));
export default App;
