import { h } from "preact";
import { CallToActionText } from "../../tokens";
var formOptinCheck = function (id, 
// FIXME: refactor out _ below
_value, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
fn, label, subhead) { return (h("div", null,
    h("input", { type: "checkbox", name: id, id: id, className: "mr-2", style: { position: "relative", top: "-3px" }, onClick: fn }),
    h("label", { htmlFor: id, className: "font-display text-sm mb-4 w-full" }, label),
    subhead !== "" ? h("p", { className: "font-body text-xs" }, subhead) : null)); };
export var CallToAction = function (props) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var emailOnChange = function (event) {
        if (event.currentTarget) {
            props.handleEmailChange(event.currentTarget.value);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onYesSubmitResultsChange = function (event) {
        if (event.currentTarget) {
            props.handleCheckboxChange({
                yesSubmitResults: event.currentTarget.checked,
            });
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onYesMailingListChange = function (event) {
        if (event.currentTarget) {
            props.handleCheckboxChange({ yesMailingList: event.currentTarget.checked });
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onYesContactMeChange = function (event) {
        if (event.currentTarget) {
            props.handleCheckboxChange({ yesContactMe: event.currentTarget.checked });
        }
    };
    return (h("div", null,
        h("p", { className: "font-display text-xl leading-tight mb-4" }, CallToActionText.title),
        h("label", { htmlFor: "cta-email", className: "font-display text-sm", id: "cta-email-label" }, CallToActionText.labelEmail),
        h("input", { name: "cta-email", id: "cta-email", type: "email", placeholder: CallToActionText.placeholderEmail, className: "bg-white rounded-none py-2 px-4 block w-full appearance-none leading-normal mt-2 font-body text-xs " + (props.errorEmail ? 'border-violet border-4' : 'border border-grayMid mb-4'), value: props.email, onChange: emailOnChange }),
        props.errorEmail && (h("div", { className: "bg-violet font-display text-white p-2 mb-2" }, props.errorEmailMessage)),
        formOptinCheck("optin-yesMailingList", props.yesMailingList, onYesMailingListChange, CallToActionText.labelYesMailingList, CallToActionText.subheadYesMailingList),
        formOptinCheck("optin-yesContactMe", props.yesContactMe, onYesContactMeChange, CallToActionText.labelYesContact, CallToActionText.subheadYesContact),
        h("br", null),
        h("div", { className: "text-center" },
            h("button", { class: "bg-white hover:bg-violet hover:text-white hover:border-violet font-display text-black border-2 border-grayMid font-display text-xs py-3 px-24", onClick: props.handleSubmit }, CallToActionText.button))));
};
export default CallToAction;
