/** constant values used by calculations below */
var PERSON_HOUR_EUROS = 37.59;
var AVG_TIME_SIGNING_MANUALLY_HOURS = 0.25;
var MAILING_COST_EUROS = 8.4;
var CO2_FOOTPRINT_PAPER_SHEET_GRAMS = 905;
var WEIGHT_OF_PAPER_SHEET_KG = 0.005;
var PRINTING_COST_EUROS = 0.36;
var MONTHS_IN_YEAR = 12;
var IMPACT_FIN_DIVISOR = 100000;
var IMPACT_SOC_DIVISOR = 200;
var IMPACT_ENV_DIVISOR = 200000;
var DAILY_WORK_HOURS = 7.5;
var WORK_DAYS_IN_MONTH = 20;
var COST_OF_SPRINT = 10000;
// Calculations below are monthly
export var calcSocial = function (signedContracts, percentageMailed, percentageMaildElectronically) {
    return signedContracts *
        AVG_TIME_SIGNING_MANUALLY_HOURS *
        (percentageMailed + percentageMaildElectronically);
};
export var printingCost = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return signedContracts *
        copies *
        pages *
        (percentageMailed + percentageMailedElectronic) *
        PRINTING_COST_EUROS;
};
export var mailingCost = function (signedContracts, percentageMailed) { return signedContracts * percentageMailed * MAILING_COST_EUROS; };
export var salaryCost = function (signedContracts, percentageMailed, percentageMailedElectronic) {
    return signedContracts *
        AVG_TIME_SIGNING_MANUALLY_HOURS *
        (percentageMailed + percentageMailedElectronic) *
        PERSON_HOUR_EUROS;
};
export var calcFinacial = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return printingCost(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) +
        mailingCost(signedContracts, percentageMailed) +
        salaryCost(signedContracts, percentageMailed, percentageMailedElectronic);
};
/** returns in sheets of paper used */
export var calcEnvironmental = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return signedContracts *
        pages *
        copies *
        (percentageMailed + percentageMailedElectronic);
};
/** general multiplier to get yearly amount */
var calcYear = function (x) { return x * MONTHS_IN_YEAR; };
/* Impact Calculations below */
export var calcFinacialImpact = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return calcYear(calcFinacial(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic)) / IMPACT_FIN_DIVISOR;
};
export var calcSocialImpact = function (signedContracts, percentageMailed, percentageMailedElectronic) {
    return calcYear(calcSocial(signedContracts, percentageMailed, percentageMailedElectronic)) / IMPACT_SOC_DIVISOR;
};
export var calcEnvironmentalImpact = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return calcYear(calcEnvironmental(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic)) / IMPACT_ENV_DIVISOR;
};
/** hepler function to return all calculations */
export var calculateImpact = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) { return ({
    fin: calcFinacialImpact(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic),
    soc: calcSocialImpact(signedContracts, percentageMailed, percentageMailedElectronic),
    env: calcEnvironmentalImpact(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic),
}); };
export var pagesUsedPerYear = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return calcEnvironmental(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) * MONTHS_IN_YEAR;
};
export var carbonFromPaperPerYear = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return (calcEnvironmental(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) *
        MONTHS_IN_YEAR *
        CO2_FOOTPRINT_PAPER_SHEET_GRAMS *
        WEIGHT_OF_PAPER_SHEET_KG) /
        1000;
};
export var calcFinacialSavingsPerYear = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return calcFinacial(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) * MONTHS_IN_YEAR;
};
export var sprintsPerYear = function (signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) {
    return calcFinacialSavingsPerYear(signedContracts, pages, copies, percentageMailed, percentageMailedElectronic) / COST_OF_SPRINT;
};
export var hoursSavedPerYear = function (signedContracts, percentageMailed, percentageMailedElectronic) {
    return calcSocial(signedContracts, percentageMailed, percentageMailedElectronic) *
        MONTHS_IN_YEAR;
};
export var savedPersonMonthsPerYear = function (signedContracts, percentageMailed, percentageMailedElectronic) {
    return hoursSavedPerYear(signedContracts, percentageMailed, percentageMailedElectronic) /
        DAILY_WORK_HOURS /
        WORK_DAYS_IN_MONTH;
};
