import { h } from "preact";
import { color } from "../../styles/tokens";
// Constant Values
var CHART_SCALAR = 0.9;
var CHART_SIZE = 650;
var NO_SCALE = "non-scaling-stroke";
var CHART_VALUE_MIN = -5;
var CHART_VALUE_MAX = 5;
var colorSeq = [color.violet, color.yellow, color.green];
var maxChartRadius = (CHART_SIZE * CHART_SCALAR) / 2;
var polarToX = function (theta, radius) {
    return Math.cos(theta - Math.PI / 2) * radius;
};
var polarToY = function (theta, radius) {
    return Math.sin(theta - Math.PI / 2) * radius;
};
var polarToXY = function (theta, radius) { return [
    polarToX(theta, radius),
    polarToY(theta, radius),
]; };
var pathData = function (verts) {
    return verts.map(function (v, i) { return (i ? "L" : "M") + " " + v[0] + " " + v[1]; }).join(" ") + " z";
};
var triVerts = function (radius) {
    var steps = [0, 1, 2];
    return steps.map(function (s) { return polarToXY((s * Math.PI * 2) / steps.length, radius); });
};
var graphVerts = function (radii) {
    var steps = [0, 1, 2];
    return steps.map(function (s) {
        return polarToXY((s * Math.PI * 2) / steps.length, radii[s]);
    });
};
var gradientLinearLine = function (gradientName, startColor, endColor, x1, y1, x2, y2) {
    var px = function (val) { return val + "px"; };
    return (h("linearGradient", { id: gradientName, x1: px(x1), y1: px(y1), x2: px(x2), y2: px(y2), gradientUnits: "userSpaceOnUse" },
        h("stop", { offset: "0%", stopColor: startColor }),
        h("stop", { offset: "100%", stopColor: endColor })));
};
var line = function (color, x1, y1, x2, y2) {
    return (h("line", { x1: x1, y1: y1, x2: x2, y2: y2, stroke: color, strokeWidth: "4px", strokeLinecap: "round" }));
};
var lineGrad = function (gradName, startColor, endColor, x1, y1, x2, y2) {
    return (h("g", { key: gradName },
        gradientLinearLine(gradName, startColor, endColor, x1, y1, x2, y2),
        line("url(#" + gradName + ")", x1, y1, x2, y2)));
};
var limitMinMax = function (value) {
    return Math.min(Math.max(value, CHART_VALUE_MIN), CHART_VALUE_MAX);
};
var chartScalar = function (value) {
    return (limitMinMax(value) + -CHART_VALUE_MIN) / (CHART_VALUE_MAX - CHART_VALUE_MIN) *
        maxChartRadius;
};
var graph = function (values) {
    // TODO: normalize smallest value so -5 or lower would display
    var verts = graphVerts(values.map(chartScalar));
    // manually making here to get in right pattern
    var adjVerts = [
        [verts[0], verts[1]],
        [verts[1], verts[2]],
        [verts[2], verts[0]],
    ];
    var rotateI = function (i) { return (i > 2 ? 0 : i); };
    return adjVerts.map(function (l, i) {
        return lineGrad("graph_line_" + i, colorSeq[rotateI(i)], colorSeq[rotateI(i + 1)], l[0][0], l[0][1], l[1][0], l[1][1]);
    });
};
var tri = function (radius, dashed) { return (h("path", { d: pathData(triVerts(radius)), fill: "none", stroke: color.grayGraph, strokeWidth: 1, strokeDasharray: dashed ? 10 : 0, vectorEffect: NO_SCALE })); };
/** draws lines from center of graph to each vert in a triangle aka the background of the graph */
var invertedTri = function (radius) {
    return triVerts(radius).map(function (v, i) { return (h("line", { key: i, x1: 0, y1: 0, x2: v[0], y2: v[1], stroke: color.grayGraph, vectorEffect: NO_SCALE })); });
};
var labels = function (finLabel, socLabel, envLabel) {
    var theta = Math.PI * 2 / 3;
    return (h("g", { id: "labels" },
        h("text", { fontFamily: "Cardo", fontSize: "18", fontWeight: "normal", "line-spacing": "30", fill: "#0F0F0F", textAnchor: "middle" },
            h("tspan", { x: "0", y: -maxChartRadius - 10 }, finLabel)),
        h("text", { fontFamily: "Cardo", fontSize: "18", fontWeight: "normal", "line-spacing": "30", fill: "#0F0F0F", textAnchor: "end" },
            h("tspan", { x: polarToX(theta, maxChartRadius), y: polarToY(theta, maxChartRadius) + 10 * 2.5 }, socLabel)),
        h("text", { fontFamily: "Cardo", fontSize: "18", fontWeight: "normal", "line-spacing": "30", fill: "#0F0F0F", textAnchor: "start" },
            h("tspan", { x: polarToX(theta * 2, maxChartRadius), y: polarToY(theta * 2, maxChartRadius) + 10 * 2.5 }, envLabel))));
};
var legend = function (impactLabel, averageLabel) { return (h("g", { id: "legend", transform: "translate(-280,180)" },
    h("text", { id: "Average-within-your", fontFamily: "Cardo", fontSize: "18", fontWeight: "normal", "line-spacing": "30", fill: "#0F0F0F" },
        h("tspan", { x: "335", y: "21" }, averageLabel)),
    h("text", { id: "Your-potential-impac", fontFamily: "Cardo", fontSize: "18", fontWeight: "normal", "line-spacing": "30", fill: "#0F0F0F" },
        h("tspan", { x: "61", y: "21" }, impactLabel)),
    h("linearGradient", { x1: "0%", y1: "50.4326719%", x2: "100%", y2: "50.4326663%", id: "legend-impact-gradient" },
        h("stop", { stopColor: color.green, offset: "0%" }),
        h("stop", { stopColor: color.yellow, offset: "49.6964738%" }),
        h("stop", { stopColor: color.violet, offset: "100%" })),
    h("rect", { id: "Rectangle", fill: "url(#legend-impact-gradient)", x: "0", y: "14", width: "43", height: "4" }),
    h("line", { x1: "317", y1: "15.5", x2: "274", y2: "15.5", id: "Path-33", stroke: "#979797", strokeDasharray: "10" }))); };
var RadarChart = function (props) {
    // const data = [props.dataFin, props.dataSoc, props.dataEnv];
    return (h("svg", { version: "1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 " + CHART_SIZE + " " + (CHART_SIZE - 80) },
        h("g", { transform: "translate(" + CHART_SIZE / 2 + "," + CHART_SIZE / 2 + ")" },
            invertedTri((CHART_SIZE / 2) * CHART_SCALAR),
            tri(maxChartRadius, false),
            props.data && props.data.average && tri(chartScalar(props.data.average), true),
            props.data && graph([props.data.fin, props.data.soc, props.data.env]),
            legend(props.labels.legendChart, props.labels.legendAverage),
            labels(props.labels.fin, props.labels.soc, props.labels.env))));
};
export default RadarChart;
